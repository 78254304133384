<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="4">
        <v-card>
          <v-card-title class="">
            Отчет о нагрузке специалистов отдела
          </v-card-title>
          <v-card-subtitle>
            Информация об отчете
          </v-card-subtitle>
          <div>
            <v-row class="mx-1">
              <v-col>
                <label>
                  Дата начала
                  <custom-date-picker v-model="from" />
                </label>
              </v-col>
              <v-col>
                <label> Дата окончания
                  <custom-date-picker v-model="to" />
                </label>
              </v-col>
              <v-col
                cols="12"
                class="pb-0"
              >
                <v-checkbox
                  v-model="withCounterAgent"
                  tabindex="-1"
                  dense
                  color="black"
                  label="C подробностями по резидентам"
                />
                <v-checkbox
                  v-model="withCorrection"
                  tabindex="-1"
                  dense
                  color="black"
                  label="Учитывать аннулированные и корректирующие"
                />
              </v-col>
            </v-row>

            <v-card-actions class="mt-10 mx-2">
              <v-btn
                class="elevation-0"
                dark
                :loading="loading"
                :disabled="loading"
                @click="downloadReport"
              >
                <v-icon>mdi-download</v-icon> Скачать
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import CustomDatePicker from '../shared/custom-date-picker.vue';
import { downloadExcel, getFilenameFromContentDisposition} from '@/helpers/documents'
import { mapGetters } from 'vuex';
import moment from 'moment'

export default {
  components:{CustomDatePicker},
  data:() =>({
    from:null,
    to:null,
    withCorrection: false,
    withCounterAgent: false,
    loading:false,
  }),
  computed:{
    ...mapGetters({
      user:'auth/getUser',
      divisions:'auth/getDivisions'
    })
  },
  methods:{
    downloadReport(){
      this.loadingStart()

      const from = moment(this.from).isValid()
        ? moment(this.from).format("DD.MM.YYYY")
        : null;
      const to = moment(this.to).isValid()
        ? moment(this.to).format("DD.MM.YYYY")
        : null;
      const withCorrection = this.withCorrection
      const withCounterAgent = this.withCounterAgent


      if(!from || !to){
        this.loadingEnd()
        return this.$snackbar({text:'Неверная дата', color:'red', top:false})
      }

      this.$store.dispatch('statistics/employeeEfficiency', {from, to, withCorrection, withCounterAgent}).then(res => {
        const name = getFilenameFromContentDisposition(res)
        downloadExcel(res.data, name)
      }).catch(() => {
        this.$snackbar({text:'Не удалось сохранить файл', color:'red', top:false})
      }).finally(() => {
        this.loadingEnd()
      })
    },
    loadingStart(){
      this.loading = true
    },
    loadingEnd(){
      this.loading = false
    },
    getCurrentCompanyUnp(){
      const divisionId = this.user.user.activeDivisionId
      const division = this.divisions.find(i => i.real_division_id === divisionId)
      return division?.tax_payer_num ?? null
    }
  }

};
</script>
